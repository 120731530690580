var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.matchData.info.teams
        ? _c("MatchStatKillBars", {
            attrs: { teams: _vm.matchData.info.teams }
          })
        : _vm._e(),
      _vm._l(_vm.matchData.info.teams, function(team, team_id) {
        return _c(
          "div",
          { key: team_id, staticClass: "mt-5 mb-5" },
          [
            _c("b-table", {
              attrs: {
                borderless: "",
                hover: "",
                fields: _vm.fields,
                items: team.players
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "cell(player)",
                    fn: function(data) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/team/" + team.id } },
                              [
                                _c("TeamLogo", {
                                  attrs: {
                                    src: team.icon_url,
                                    team_name: team.name
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-2" },
                              [
                                _c(
                                  "h2",
                                  [
                                    data.item.id
                                      ? _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/player/" +
                                                data.item.id +
                                                "/stats/matchData.info.map"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.game_name) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(data.item.game_name) +
                                              " "
                                          )
                                        ])
                                  ],
                                  1
                                ),
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "text-muted font-weight-lighter",
                                    attrs: { to: "/team/" + team.id }
                                  },
                                  [_vm._v(" " + _vm._s(team.name) + " ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(agent)",
                    fn: function(data) {
                      return [
                        _c("img", {
                          staticClass: "img-fluid",
                          staticStyle: { "max-height": "40px" },
                          attrs: {
                            src: "/images/agents/" + data.value + ".png",
                            alt: ""
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "cell()",
                    fn: function(data) {
                      return [
                        _c(
                          "div",
                          {
                            class: [
                              "info-cell",
                              "d-flex",
                              "justify-content-center",
                              "align-items-center",
                              "info-cell--" + (!data.value ? "empty" : "full")
                            ]
                          },
                          [
                            _vm._v(
                              " " + _vm._s(data.value ? data.value : "·") + " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      }),
      _c("MatchStatKillMatrix", { attrs: { "match-data": _vm.matchData.info } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }